import { lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider as BaseRouteProvider } from 'react-router-dom';
import { Page } from '../components/Page';

// Shared
const AppRoot = lazy(() => import('./App/AppRoot'));
const NotFound = lazy(() => import('./NotFound/NotFound'));

// Profile
const ProfileLayout = lazy(() => import('../layout/ProfileLayout'));
const Profile = lazy(() => import('./Profile/Profile'));

const router = createBrowserRouter(
  [
    {
      path: '/not-found',
      element: (
        <Page name='NotFoundPage'>
          <NotFound />
        </Page>
      ),
    },
    {
      path: '/',
      element: (
        <Page name='AppRoot'>
          <AppRoot>
            <Outlet />
          </AppRoot>
        </Page>
      ),
      children: [
        {
          path: '/',
          element: <Navigate to='/profile' replace />,
        },
        {
          path: 'profile',
          element: (
            <Page name='Profile'>
              <ProfileLayout />
            </Page>
          ),
          children: [
            {
              index: true,
              element: (
                <Page name='Profile'>
                  <Profile />
                </Page>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/not-found' replace={true} />,
    },
  ],
  {
    basename: process.env.REACT_APP_ROUTER_BASENAME,
  }
);

const RouteProvider = () => <BaseRouteProvider router={router} />;

export { RouteProvider };
