import { BusinessUnitGroup, getSupportedLocaleForBug, SupportedLocale } from '@eftours/cx-tours-helpers';

type Content = Record<string, string>;

type ContentByLanguage = Partial<Record<SupportedLocale, Content>>;

/**
 * This imports the appropriate BUG specific JSON content file and returns just the most appropriate language from it.
 *
 * The content files override the "defaultMessage" prop in code. For ETUS English content, an empty object is returned.
 * (That is, no overrides, just use the default.)
 *
 * Currently we only have English content for all BUGs, plus French for Canada, but this allows for the user's browser setting
 * to override the primary language for the locale selected based on the host domain.
 */
const getAppContent = async (businessUnitGroup: BusinessUnitGroup, locale: SupportedLocale): Promise<Content> => {
  let contentByLanguage: ContentByLanguage;

  // lazy load just the BUG specific content we need
  switch (businessUnitGroup) {
    case BusinessUnitGroup.ExploreAmerica:
      contentByLanguage = await import('./EAUS.json');
      break;
    case BusinessUnitGroup.EFToursCanada:
      contentByLanguage = await import('./ETCA.json');
      break;
    case BusinessUnitGroup.StudyAbroad:
      contentByLanguage = await import('./CST.json');
      break;
    case BusinessUnitGroup.EFTours:
    default:
      // ETUS English content is in code as the default, so we return an empty object of overrides
      contentByLanguage = {};
      break;
  }

  /**
   * FIXME: Currently we only support the product and domain based language. (ie, English for all but efvoyages.ca, which is French).
   *
   * We're slightly abusing the idea of "internationalization" to include product-differentiated content. Consider a system similar to
   * tours-authentication-ui, which separates translated content from product specific content and uses a package to determine
   * the users's preferred locale based on window.navigator.languages
   *
   * see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages for info on language/locale codes present in navigator.languages
   * see https://github.com/eftours/tours-authentication-ui/blob/47ddc7c938412e72317e34b587167c2b836953ec/src/utils/internationalization/content.ts#L57
   * for an implementation
   */

  const language = getSupportedLocaleForBug(businessUnitGroup, locale);
  return contentByLanguage[language] || {};
};

export { getAppContent };
