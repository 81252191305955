import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { WrappedProductFooter } from '../Navigation/WrappedProductFooter';
import { MainContainer, MainFull } from '../../layout/Container';
import { logger } from '../../services/logger/logger';
import { useIntlAttribute } from '../../hooks/useIntlAttribute';
import { FormattedMessageWithTags } from '../FormattedMessageWithTags';
import { ErrorAirplanes } from './ErrorAirplanes';

type ErrorProps = {
  statusCode?: string | number;
};

const Error = ({ statusCode }: ErrorProps) => {
  const correlationId = logger.getCorrelationId();

  return (
    <Stack direction='column' justifyContent='center' alignItems='center' spacing={2} textAlign='center'>
      {statusCode && (
        <Typography color='text.disabled'>
          <div>
            <FormattedMessage
              id='Error.StatusCodeTitle'
              defaultMessage='Error code: {statusCode}'
              values={{ statusCode }}
            />
          </div>
          <div>
            <FormattedMessage
              id='Error.StatusCodeDescription'
              defaultMessage='Request failed with status code {statusCode}'
              values={{ statusCode }}
            />
          </div>
        </Typography>
      )}

      <Typography variant='h4' sx={{ pb: 5 }}>
        <FormattedMessage id='Error.Header' defaultMessage='Oh no, something went wrong!' />
      </Typography>
      <ErrorAirplanes />
      <Typography sx={{ pt: 4, maxWidth: 'sm' }}>
        <FormattedMessageWithTags
          id='Error.body'
          defaultMessage='We recommend reloading the page. If you need immediate assistance, please visit the <helpCenterLink>Help Center</helpCenterLink> or contact <contactLink>Traveler Support</contactLink>. Thank you for your patience!'
          values={{
            helpCenterLink: (chunks: React.ReactNode) => (
              <Link
                href={useIntlAttribute({
                  id: 'Error.HelpCenterLink',
                  defaultMessage: 'https://www.eftours.com/help-center',
                })}
              >
                {chunks}
              </Link>
            ),
            contactLink: (chunks: React.ReactNode) => (
              <Link
                href={useIntlAttribute({
                  id: 'Error.ContactUsLink',
                  defaultMessage: 'https://www.eftours.com/contact-us',
                })}
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </Typography>

      {correlationId && (
        <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
          <FormattedMessage
            id='Error.correlationId'
            defaultMessage='Request Id: {correlationId}'
            values={{ correlationId }}
          />
        </Typography>
      )}
    </Stack>
  );
};

const ErrorPage = ({ ...props }: ErrorProps) => {
  return (
    <MainFull>
      <Error {...props} />
    </MainFull>
  );
};

const ErrorPageWithLayout = ({ ...props }: ErrorProps) => {
  return (
    <>
      <MainContainer>
        <ErrorPage {...props} />
      </MainContainer>
      <WrappedProductFooter />
    </>
  );
};

export { Error, ErrorPage, ErrorPageWithLayout };
