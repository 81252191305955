import { IntlProvider } from 'react-intl';
import { createDataProvider } from '@eftours/cx-react-utils';
import { SupportedLocale, BusinessUnitGroup } from '@eftours/cx-tours-helpers';
import { parseProductHostname, getSupportedProduct, getLocale } from '@tours-account-profile-shared/types';

import { getAppContent } from '../../services/content/content';

const DEFAULT_LOCALE = SupportedLocale.AmericanEnglish;

type ProductContext = {
  businessUnitGroup: BusinessUnitGroup;
  locale: SupportedLocale;
  content: Record<string, string>;
};

const getProductContext = async () => {
  const { productHostname } = parseProductHostname(window.location.hostname);
  const businessUnitGroup = getSupportedProduct(productHostname);
  const locale = getLocale(productHostname);
  const content = await getAppContent(businessUnitGroup, locale);

  return Promise.resolve({ businessUnitGroup, locale, content });
};

type AppContentProviderProps = {
  children: React.ReactNode;
};

const AppContentProvider = ({ children }: AppContentProviderProps) => {
  const { content, locale } = useProductContext().data;

  return (
    <IntlProvider messages={content} locale={locale} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </IntlProvider>
  );
};

const [useProductContext, ProductContextProvider] = createDataProvider<ProductContext>({});

export { getProductContext, useProductContext, ProductContextProvider, AppContentProvider };
