import { ProductFooter } from '@eftours/cx-shared-components-global-footer';
import { SxProps } from '@mui/material';
import { SupportedLocale, BusinessUnitGroup } from '@eftours/cx-tours-helpers';
import { useProductContext } from '../../services/productContext/ProductProvider';

/**
 * Shared global visual layout. Header, footer, main sections etc
 */
const WrappedProductFooter = ({ sx = [] }: { sx?: SxProps }) => {
  const { businessUnitGroup, locale } = useProductContext().data;

  //FIXME: remove once footer can accept locale
  const footerFriendlyBug =
    businessUnitGroup == BusinessUnitGroup.EFToursCanada && locale == SupportedLocale.CanadianFrench
      ? 'ETFC'
      : businessUnitGroup;

  return <ProductFooter productCode={footerFriendlyBug} sx={sx} data-print-hide />;
};

export { WrappedProductFooter };
