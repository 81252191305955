"use strict";
exports.__esModule = true;
exports.parseProductHostname = exports.getLocale = exports.getSupportedProduct = void 0;
var cx_tours_helpers_1 = require("@eftours/cx-tours-helpers");
/**
 * parseProductHostname returns a key/value object that can be used to resolve templated string configs
 * that are dependent on the context of a given request in order to use.
 *
 * rootHostname: root domain
 * productHostname: domain without the first subdomain
 *
 * see mdn for details on other keys: https://developer.mozilla.org/en-US/docs/Web/API/URL#properties
 */
var parseProductHostname = function (hostname) {
    var domainParts = hostname.split('.');
    var rootHostname = domainParts.slice(domainParts.length - 2).join('.');
    var productHostname = domainParts.length > 2 ? domainParts.slice(1).join('.') : domainParts.join('.');
    return {
        productHostname: productHostname,
        rootHostname: rootHostname
    };
};
exports.parseProductHostname = parseProductHostname;
var getSupportedProduct = function (domain) {
    switch (domain) {
        case 'eaus.localhost': // local development
        case 'eaus.tourslocal.com': // local development with custom hosts file
        case 'eaus-q.tourslabs.com': // QA
        case 'efexploreamerica.com': // should handle stage and prod
            return cx_tours_helpers_1.BusinessUnitGroup.ExploreAmerica;
        case 'cst.localhost':
        case 'cst.tourslocal.com':
        case 'cst-q.tourslabs.com':
        case 'efstudyabroad.com':
            return cx_tours_helpers_1.BusinessUnitGroup.StudyAbroad;
        case 'etca.localhost':
        case 'etca.tourslocal.com':
        case 'etca-q.tourslabs.com':
        case 'eftours.ca':
        case 'etfc.localhost':
        case 'etfc.tourslocal.com':
        case 'etfc-q.tourslabs.com':
        case 'efvoyages.ca':
            return cx_tours_helpers_1.BusinessUnitGroup.EFToursCanada;
        default:
            return cx_tours_helpers_1.BusinessUnitGroup.EFTours;
    }
};
exports.getSupportedProduct = getSupportedProduct;
var getLocale = function (domain) {
    /**
     * FIXME: Current implementation does initial check for locale by domain. Ideally we'd take into
     * consideration `accepts-languages` header or, `navigator.languages` in browser, to determine
     * language/locale and content, especially as we support more languages. (ie Spanish for EAUS)
     */
    switch (domain) {
        case 'efvoyages.ca': // should handle stage and prod
        case 'etfc-q.tourslabs.com': // qa
        case 'etfc.localhost': // local development
        case 'etfc.tourslocal.com': // local development with custom hosts file
            return cx_tours_helpers_1.SupportedLocale.CanadianFrench;
        case 'eftours.ca':
        case 'etca-q.tourslabs.com':
        case 'etca.localhost':
        case 'etca.tourslocal.com':
            return cx_tours_helpers_1.SupportedLocale.CanadianEnglish;
        default:
            return cx_tours_helpers_1.SupportedLocale.AmericanEnglish;
    }
};
exports.getLocale = getLocale;
