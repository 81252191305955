import { SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ResponsiveStyleValue } from '@mui/system';
import Container from '@mui/system/Container';

type MainProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

type MainColumnProps = MainProps & {
  spacing?: ResponsiveStyleValue<string | number>;
};

const MainContainer = ({ children, sx }: MainProps) => {
  return (
    <Container sx={sx}>
      <Grid container direction='row' justifyContent='center' pt={8} spacing={{ md: 3 }} data-print-page>
        {children}
      </Grid>
    </Container>
  );
};

const MainFull = ({ children, sx }: MainProps) => {
  return (
    <Grid item xs={12} px={{ xs: 0, md: 3 }}>
      <MainColumn sx={[...(Array.isArray(sx) ? sx : [sx])]}>{children}</MainColumn>
    </Grid>
  );
};

const MainColumn = ({ children, spacing, sx }: MainColumnProps) => {
  return (
    <Stack spacing={spacing ?? 3} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {children}
    </Stack>
  );
};

const MainLeft = ({ children, sx }: MainProps) => {
  return (
    <Grid item xs={12} md={8} pb={3}>
      <MainColumn sx={sx}>{children}</MainColumn>
    </Grid>
  );
};

const MainRight = ({ children, sx }: MainProps) => {
  return (
    <Grid item xs={12} md={4} pb={3}>
      <MainColumn sx={sx} spacing={{ xs: 3, md: 2 }}>
        {children}
      </MainColumn>
    </Grid>
  );
};

export { MainContainer, MainFull, MainLeft, MainRight };
