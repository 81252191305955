"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.parseProductHostname = exports.getLocale = exports.getSupportedProduct = exports.ResourceType = exports.RoleType = exports.Environment = void 0;
var app_1 = require("./app");
__createBinding(exports, app_1, "Environment");
var person_1 = require("./person");
__createBinding(exports, person_1, "RoleType");
var tours_1 = require("./tours");
__createBinding(exports, tours_1, "ResourceType");
var product_1 = require("./product");
__createBinding(exports, product_1, "getSupportedProduct");
__createBinding(exports, product_1, "getLocale");
__createBinding(exports, product_1, "parseProductHostname");
